import { render, staticRenderFns } from "./SectionBuilding.vue?vue&type=template&id=328a34fc&"
import script from "./SectionBuilding.vue?vue&type=script&lang=js&"
export * from "./SectionBuilding.vue?vue&type=script&lang=js&"
import style0 from "./SectionBuilding.vue?vue&type=style&index=0&id=328a34fc&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OrganismsProjectBuildingOrganism3DContainer: require('/app/components/organisms/project/building/Organism3DContainer.vue').default,OrganismsProjectBuildingOrganismGuidedTourPresentationDrawer: require('/app/components/organisms/project/building/OrganismGuidedTourPresentationDrawer.vue').default,OrganismsProjectBuildingOrganismProjectUI: require('/app/components/organisms/project/building/OrganismProjectUI.vue').default,OrganismsProjectBuildingOrganismSpace: require('/app/components/organisms/project/building/OrganismSpace.vue').default,AtomsCommonAtomIcon: require('/app/components/atoms/common/AtomIcon.vue').default})
